var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    _vm.b(''),
    {
      'is-fullscreen': _vm.isFullScreenOpened,
      'is-full-mob-up-desc': _vm.isFullMobDesc,
    },
  ]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.isBreakpointMatches && _vm.isFullScreenOpened)),expression:"!(isBreakpointMatches && isFullScreenOpened)"},{name:"touch",rawName:"v-touch:swipe.left",value:(_vm.changeImageRight),expression:"changeImageRight",arg:"swipe",modifiers:{"left":true}},{name:"touch",rawName:"v-touch:swipe.right",value:(_vm.changeImageLeft),expression:"changeImageLeft",arg:"swipe",modifiers:{"right":true}}],class:[_vm.b('images'), { 'disable-slider': !_vm.isImagesLoaded }],on:{"click":_vm.onClickImages,"wheel":_vm.onScrollFullScreen}},[_c('div',{class:_vm.b('image-wrap')},_vm._l((_vm.oRoomData.images),function(image,index){return _c('picture',{key:image + index,staticClass:"lazy-picture",class:{ active: index === _vm.hoveredImageIndex }},[(index === 0)?[_c('source',{attrs:{"sizes":"100vw","type":"image/webp","srcset":image.url,"alt":`${_vm.oRoomData.name}${
              _vm.oRoomData.images.length > 1 ? ` - фото №${index + 1}` : ''
            }`}}),_c('img',{class:_vm.b('image'),attrs:{"src":image['fallback-url'],"alt":`${_vm.oRoomData.name}${
              _vm.oRoomData.images.length > 1 ? ` - фото №${index + 1}` : ''
            }`}})]:(_vm.domLoaded)?[_c('source',{attrs:{"sizes":"100vw","type":"image/webp","srcset":image.url,"alt":`${_vm.oRoomData.name}${
              _vm.oRoomData.images.length > 1 ? ` - фото №${index + 1}` : ''
            }`}}),_c('img',{class:_vm.b('image'),attrs:{"src":image['fallback-url'],"alt":`${_vm.oRoomData.name}${
              _vm.oRoomData.images.length > 1 ? ` - фото №${index + 1}` : ''
            }`},on:{"load":_vm.onLoadImages}})]:_vm._e()],2)}),0),_c('div',{class:{
        'slider-container': !_vm.isFullScreenOpened,
        'slider-container-full': _vm.isFullScreenOpened,
      }},[(_vm.oRoomData.images.length > 1)?_c('div',{staticClass:"slider-box"},_vm._l((_vm.oRoomData.images),function(image,index){return _c('div',{key:image + index,staticClass:"slider-box__el",class:index === _vm.hoveredImageIndex ? 'active' : '',on:{"mouseover":function($event){return _vm.onHoverImageHelper(index)}}})}),0):_vm._e()])]),(_vm.isBreakpointMatches && _vm.isFullScreenOpened)?_c('div',{class:_vm.b('mobile-item'),on:{"scroll":_vm.onScrollGallery}},_vm._l((_vm.oRoomData.images),function(image,index){return _c('div',{key:image + index,class:_vm.b('item')},[_c('img',{attrs:{"src":image.url,"alt":`${_vm.oRoomData.name}${
          _vm.oRoomData.images.length > 1 ? ` - фото №${index + 1}` : ''
        }`}})])}),0):_vm._e(),_c('div',{class:_vm.b('footer')},[_c('div',{class:_vm.b('footer-container')},[(
          _vm.oRoomData.images.length > 1 && !(_vm.isFullScreenOpened && _vm.isMobile)
        )?_c('div',{staticClass:"lines-slider"},_vm._l((_vm.oRoomData.images),function(image,index){return _c('div',{key:image + index,staticClass:"lines-slider__el",class:index === _vm.hoveredImageIndex ? 'active' : '',on:{"mouseover":function($event){return _vm.onHoverImageHelper(index)}}})}),0):_vm._e(),_c('button',{class:['btn btn--close', _vm.b('btn-close')],on:{"click":_vm.closeFullScreen}},[_c('BaseIcon',{attrs:{"icon-name":"times","fill":'white',"width":16,"height":16}})],1),_c('div',{class:_vm.b('desc')},[_c('div',{class:_vm.b('desc-title')},[_vm._v(" "+_vm._s(this.oRoomData.name)+" ")]),_c('div',{class:_vm.b('desc-btns')},[_c('div',{class:_vm.b('desc-price')},[_vm._v("от 500 р/час")])])]),_c('div',{class:_vm.b('footer-btns')},[_c('BaseButton',{class:_vm.b('btn'),attrs:{"color":"white","medium":true},on:{"click":_vm.onFullScreenGallery}},[_vm._v(" Полноэкранный режим ")]),_c('span',{class:_vm.b('counter-text')},[_vm._v(" "+_vm._s(_vm.oRoomData.images.length)+" фото ")])],1),_c('div',{staticClass:"swiper-pagination",on:{"mouseover":_vm.onMouseOverPagination}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }