
import { Component, Vue } from 'vue-property-decorator'

// import swipeImages from '~/mixins/swipeImages'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

@Component({
  name: 'ImplementerSlider',
  components: {
    BaseButton,
    BaseIcon,
  },
})
export default class ImplementerSlider extends Vue {
  gallerySliderApi = null
  isFullScreenOpened = false
  isFullMobDesc = false
  breakpoint = null
  isBreakpointMatches = false
  isSliderMobile = false
  isHideSlider = false
  hoveredImageIndex = 0
  imagesLoadedCount = 1
  domLoaded = false
  oSwiperOptions = {
    slidesPerView: 'auto',
    loop: true,

    pagination: {
      el: '.swiper-pagination',
      clickable: false,
    },

    keyboard: {
      enabled: false,
      onlyInViewport: true,
    },

    breakpoints: {},
  }

  oRoomData = {
    images: [
      {
        url: '',
        'fallback-url': '/img/profile/slide-1.png',
      },
      {
        url: '',
        'fallback-url': '/img/profile/portfolio-1.png',
      },
    ],
  }

  get isMobile(): boolean {
    return this.$store.state.isMobile || this.$store.state.isTablet
  }
  get isImagesLoaded(): boolean {
    if (this.oRoomData?.images) {
      return this.oRoomData.images.length === this.imagesLoadedCount
    }
    return false
  }

  public mounted(): void {
    this.breakpoint = window.matchMedia('(max-width:1023px)')

    this.$nextTick(() => {
      if (this.$refs.gallerySwiper) {
        this.gallerySliderApi = this.$refs.gallerySwiper.$sliderApi
      }
    })

    this.isBreakpointMatches = this.breakpoint.matches

    window.addEventListener('resize', () => {
      this.isBreakpointMatches = this.breakpoint.matches
    })

    document.addEventListener('fullscreenchange', (e) => {
      if (!document.fullscreen) {
        this.closeFullScreen()
      }
    })

    this.domLoaded = true
  }

  public onLoadImages(): void {
    this.imagesLoadedCount++
  }

  public closeFullScreen(): void {
    this.isFullScreenOpened = false
    this.isFullMobDesc = false
    document.querySelector('html').classList.remove('is-fullscreen')
    document.removeEventListener('keyup', this.onKeyUp)

    this.$nextTick(() => {
      this.updateSwiper()
    })

    if (document.fullscreenElement && document.exitFullscreen) {
      document.exitFullscreen()
    }
  }

  public onFullScreenGallery(): void {
    this.isFullScreenOpened = true
    document.querySelector('html').classList.add('is-fullscreen')
    document.addEventListener('keyup', this.onKeyUp)

    this.$nextTick(() => {
      this.updateSwiper()
    })

    if (!document.fullscreenElement) {
      try {
        document.documentElement.requestFullscreen()
      } catch (error) {
        console.log('Full screen mode is not supported')
      }
    }
  }

  public updateSwiper(): void {
    setTimeout(() => {
      if (this.gallerySliderApi) {
        this.gallerySliderApi.update()
      }
    }, 100)
  }

  public onScrollGallery(): void {
    const gallery = this.$el.querySelector('.implementer-slider__mobile-item')

    if (this.isFullScreenOpened && this.isBreakpointMatches) {
      const heightLastPhoto = gallery.querySelector(
        '.implementer-slider__item:last-child'
      ).offsetHeight
      const scroll = gallery.scrollTop
      const height = gallery.offsetHeight
      const scrollHeight = gallery.scrollHeight
      const diffScroll = scrollHeight - height - scroll
      this.isFullMobDesc = diffScroll <= heightLastPhoto * 0.15
    } else {
      this.isFullMobDesc = false
    }
  }

  public onScrollFullScreen(e): void {
    if (this.isFullScreenOpened) {
      if (Math.sign(e.deltaY) === 1) {
        this.hoveredImageIndex !== this.oRoomData.images.length - 1
          ? (this.hoveredImageIndex += 1)
          : (this.hoveredImageIndex = 0)
      } else {
        this.hoveredImageIndex !== 0
          ? (this.hoveredImageIndex -= 1)
          : (this.hoveredImageIndex = this.oRoomData.images.length - 1)
      }
    }
  }

  public onMouseOverPagination(event): void {
    const target = event.target.closest('.swiper-pagination-bullet')

    if (!target) return

    this.gallerySliderApi.slideTo(
      Array.from(target.closest('.swiper-pagination-bullets').children).indexOf(
        target
      )
    )
  }

  public onHoverImageHelper(index): void {
    this.hoveredImageIndex = index
  }

  public onKeyUp({ key }): void {
    if (key === 'ArrowLeft' && this.isFullScreenOpened) {
      this.changeImageLeft()
    }
    if (key === 'ArrowRight' && this.isFullScreenOpened) {
      this.changeImageRight()
    }
  }

  public onClickImages(): void {
    if (!this.$store.state.isMobile) {
      this.onFullScreenGallery()
    }
  }

  public changeImageLeft(): void {
    this.hoveredImageIndex !== 0
      ? (this.hoveredImageIndex -= 1)
      : (this.hoveredImageIndex = this.oRoomData.images.length - 1)
  }

  public changeImageRight(): void {
    this.hoveredImageIndex !== this.oRoomData.images.length - 1
      ? (this.hoveredImageIndex += 1)
      : (this.hoveredImageIndex = 0)
  }
}
